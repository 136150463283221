import React from "react"
import { graphql } from 'gatsby'
import LayoutHeadFoot from "../components/Shared/Layouts/LayoutHeadFoot";
import SEO from "../components/seo";
import UseCases from "../components/UseCasesPage/UseCases";


const useCasesTemplate = ({ data }) => {

  const useCasesData = data?.strapiUseCases;

  return (
    <>
      <SEO seo={useCasesData.metaData} />
      <LayoutHeadFoot>
        <UseCases data={useCasesData} />
      </LayoutHeadFoot>
    </>
  )
}

export const query = graphql`
query useCasesQuery($url: String!) {
  strapiUseCases(url: {eq: $url}) {
    metaData {
      metaDescription
      metaTitle
      shareImage {
        localFile {
          publicURL
        }
      }
    }
    topBannerContent
    topBannerMedia{
        alternativeText
            caption
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData(
                    formats: [AUTO]
                  )
            }
        }
    }
    bannerBottomTextBox
    twoColItem {
        content
        image {
            alternativeText
            caption
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData(
                    formats: [AUTO]
                  )
              }
            }
        }
    }
  }
}`

export default useCasesTemplate;


