import React from "react"


const Fade = ({ children, className }) => {
    return (
        <div
            className={className}
            data-sal="fade"
            data-sal-duration="450"
            data-sal-delay="200"
            data-sal-easing="ease-out">
            {children}
        </div>
    )
}

export default Fade