import React from "react"
import classNames from "classnames"
import Image from "./Image"
import SlideUp from "./Animation/SlideUp"
import arrow from "../../images/arrows.svg"
import scrollTo from "gatsby-plugin-smoothscroll"

const TwoColContent = ({ data, bgColor, type }) => {
  return (
    <div className="container homeTwoCol justify-center flex flex-col max-width-unset">
      {data?.map((el, i) => (
        <div
          id={`homeTwoCol${i}`}
          className={classNames("lg:px-12 md:px-8 px-4 bg-white"/*, {
            "bg-white": i % 2 === 0 && !bgColor,
            "bg-white": i % 2 === 1,
          }*/)}
          key={i}
        >
          <div
            className={classNames(
              "container flex flex-col justify-between items-center gap-2 md:gap-10 py-16",
              {
                "md:flex-row full-bg": i % 2 === 1,
                "md:flex-row-reverse full-bg-reverse": i % 2 === 0,
              }
            )}
          >
            <div className="md:w-5/12 w-full">
              <SlideUp className="twocol-text">
                <div
                  className="text-editor"
                  dangerouslySetInnerHTML={{ __html: el.content }}
                />
              </SlideUp>
              {i !== data.length - 1 && type === "home" &&(
                <img
                  onClick={() => scrollTo(`#homeTwoCol${i + 1}`)}
                  className={`block cursor-pointer mt-10 relative sm:block hidden ${
                    i % 2 === 0 && "l-1"
                  }`}
                  src={arrow}
                  alt=""
                />
              )}
            </div>
            {/* Media section */}
            <SlideUp className={`md:w-7/12 w-full flex ${i % 2 === 1? "justify-end" : "justify-start"}`}>
              {el.image && (
                <Image
                  key={i}
                  media={el.image}
                  className="twocol-img obj-cont-important"
                />
              )}
              {el.caption && (
                <div className="mt-4 font-medium text-center text-sm">
                  {el.caption}
                </div>
              )}
            </SlideUp>
          </div>
        </div>
      ))}
    </div>
  )
}

export default TwoColContent
