import React from "react"
import Fade from "../Shared/Animation/Fade"
import SlideUp from "../Shared/Animation/SlideUp"
import Image from "../Shared/Image"
import TwoColContent from "../Shared/TwoColContent"


const UseCases = ({ data }) => {

    return (
        <div>
            <div className="homeBanner gradientBlue relative flex flex-col md:flex-row items-center pt-8">
                <div className="lg:container px-4 mx-auto mb-8 md:mb-0 items-center flex flex-wrap md:h-screen pt-8">
                    <div
                        data-sal="slide-down"
                        data-sal-duration="450"
                        data-sal-delay="200"
                        data-sal-easing="ease-out"
                        className="w-full mt-4 md:mt-0 md:w-6/12 pr-0 md:pr-10 text-center md:text-left">
                        <div className="mb-4">
                            <div className="text-editor" dangerouslySetInnerHTML={{ __html: data.topBannerContent }} />
                        </div>
                    </div>
                    {/* Media section */}
                    <SlideUp className="w-full md:w-6/12 md:mt-0 text-center">
                        {data.topBannerMedia && <Image media={data.topBannerMedia} className="object-contain mx-auto obj-cont-important" />}
                        {/* {data.homeBannerMedia.mime.startsWith("video") && (
                        <Video
                            media={data.homeBannerMedia}
                            poster={data.homeBannerPoster}
                            className="w-full h-auto"
                            controls={true}
                        />
                    )} */}
                        {data?.topBannerMedia?.caption &&
                            <div className="mt-4 font-medium text-center text-sm">
                                {data?.topBannerMedia?.caption}
                            </div>
                        }
                    </SlideUp>
                </div>
            </div>
            {data.bannerBottomTextBox &&
                <Fade className="container">
                    <div className="text-editor py-8" dangerouslySetInnerHTML={{ __html: data.bannerBottomTextBox }} />
                </Fade>
            }
            <TwoColContent data={data.twoColItem} bgColor="white" />
        </div>
    )
}


export default UseCases