import React from "react"


const SlideUp = ({ children, className }) => {
    return (
        <div
            className={className}
            data-sal="slide-up"
            data-sal-duration="400"
            data-sal-delay="200"
            data-sal-easing="ease">
            {children}
        </div>
    )
}

export default SlideUp